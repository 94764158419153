<template>
    <div>
        <PageHeader :title="'Validações Boletas e Contratos:'" :items="breadcrumb" />

        <b-card class="mb-3 mt-3 card-pequeno" title="Filtros">
            <b-col class="margin">
                <b-row>
                    <b-col md="2">
                        <b-form-group label="Mês de Referência" label-for="filtroMesRef" class="form">
                            <b-form-select id="filtroMesRef" v-model="filtroMesRef" :options="meses"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Ano de Referência" label-for="filtroAnoRef" class="form">
                            <b-form-input id="filtroAnoRef" type="number" v-model="filtroAnoRef"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Tipo Operação" label-for="tipoOperacao" class="form">
                            <b-form-select id="operacoes" :options="operacoes" v-model="venda" @change="onCorChange" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Cor Boletas" label-for="corBoletas" class="form">
                            <b-form-select id="corBoletas" :options="cores" v-model="cor" @change="onCorChange" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Sigla Perfil ContraParte" label-for="siglaPerfil" class="form">
                            <b-form-input id="siglaPerfil" type="text" v-model="sigla" @keyup.enter="fetchData" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Cliqccee" label-for="cliqccee" class="form">
                            <b-form-input id="cliqccee" type="text" v-model="cliqccee" @keyup.enter="fetchData" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Fonte" label-for="fonte" class="form">
                            <b-form-select id="fonte" :options="fontes" v-model="fonte" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Submercado" label-for="submercado" class="form">
                            <b-form-select id="submercado" :options="submercados" v-model="submercado" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Validação Registro">
                            <b-form-checkbox-group v-model="vr" :options="optionsCheck"></b-form-checkbox-group>
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Ajuste Registro">
                            <b-form-checkbox-group v-model="ar" :options="optionsCheck"></b-form-checkbox-group>
                        </b-form-group>
                    </b-col>

                    <b-col md="2" v-if="venda">
                        <b-form-group label="Validação Ajuste">
                            <b-form-checkbox-group v-model="va" :options="optionsCheck"></b-form-checkbox-group>
                        </b-form-group>
                    </b-col>

                    <b-col md="2" v-if="venda">
                        <b-form-group label="Status Financeiro">
                            <b-form-checkbox-group v-model="fin" :options="optionsCheck"></b-form-checkbox-group>
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Matching">
                            <b-form-checkbox-group v-model="mat" :options="optionsCheck"></b-form-checkbox-group>
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Net Energético">
                            <b-form-checkbox-group v-model="net" :options="optionsCheck"></b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="2">
                        <b-button @click="fetchData" class="buttom"
                            v-if="hasPermission('backoffice_view')">Listar</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-card>

        <b-card class="mb-3 mt-3 card-pequeno" title="Ações" v-if="hasPermission('backoffice_edit')">
            <b-col class="margin">
                <b-row>
                    <b-col md="2" v-if="hasPermission('backoffice_edit')">
                        <b-button @click="fazerMatching" class="buttom">Tentar Matching</b-button>
                    </b-col>

                    <b-col md="2" v-if="hasPermission('backoffice_edit')">
                        <b-button @click="desfazerMatching" class="buttom">Desfazer Matching</b-button>
                    </b-col>

                    <b-col md="2" v-if="venda && hasPermission('backoffice_edit')">
                        <b-button @click="criarContratos" class="buttom">Criar Contratos</b-button>
                    </b-col>

                    <b-col md="2" v-if="venda && hasPermission('backoffice_edit')">
                        <b-button @click="atualizarContratos" class="buttom">Atualizar Contratos</b-button>
                    </b-col>

                    <b-col md="2" v-if="!venda && hasPermission('backoffice_edit')">
                        <b-button @click="validarContratosMZerado" class="buttom">Validar Mwm Zerado</b-button>
                    </b-col>

                    <b-col md="2" v-if="!venda && hasPermission('backoffice_edit')">
                        <b-button @click="validarContratosAjusteM" class="buttom">Validar Ajuste Mwm</b-button>
                    </b-col>
                </b-row>

            </b-col>
        </b-card>

        <b-spinner v-if="loading" text-align="center" variant="success" label="Spinning" class="ml-2 mt-3" />

        <b-card-group deck v-if="dados">

            <b-card header="Venda CO" header-bg-variant="dark" header-text-variant="white" align="center"
                class="card-pequeno">
                <b-card-text>{{ formatNumber(this.dados.somatorio.vendaCO, 3) }}</b-card-text>
            </b-card>

            <b-card header="Venda I0" header-bg-variant="dark" header-text-variant="white" align="center"
                class="card-pequeno">
                <b-card-text>{{ formatNumber(this.dados.somatorio.vendaI0, 3) }}</b-card-text>
            </b-card>

            <b-card header="Venda I5 I1" header-bg-variant="dark" header-text-variant="white" align="center"
                class="card-pequeno">
                <b-card-text>{{ formatNumber(this.dados.somatorio.vendaI5, 3) }}</b-card-text>
            </b-card>

            <b-card header="Venda INE CQ5" header-bg-variant="dark" header-text-variant="white" align="center"
                class="card-pequeno">
                <b-card-text>{{ formatNumber(this.dados.somatorio.vendaIne, 3) }}</b-card-text>
            </b-card>

            <b-card header="Compra CO" header-bg-variant="dark" header-text-variant="white" align="center"
                class="card-pequeno">
                <b-card-text>{{ formatNumber(this.dados.somatorio.compraCO, 3) }}</b-card-text>
            </b-card>

            <b-card header="Compra I0" header-bg-variant="dark" header-text-variant="white" align="center"
                class="card-pequeno">
                <b-card-text>{{ formatNumber(this.dados.somatorio.compraI0, 3) }}</b-card-text>
            </b-card>

            <b-card header="Compra I5 I1" header-bg-variant="dark" header-text-variant="white" align="center"
                class="card-pequeno">
                <b-card-text>{{ formatNumber(this.dados.somatorio.compraI5, 3) }}</b-card-text>
            </b-card>

            <b-card header="Compra INE CQ5" header-bg-variant="dark" header-text-variant="white" align="center"
                class="card-pequeno">
                <b-card-text>{{ formatNumber(this.dados.somatorio.compraIne, 3) }}</b-card-text>
            </b-card>
        </b-card-group>

        <br>

        <MatchingTableVenda v-if="venda" :loading="loading" :dados="dadosVenda" @atualizarTabela="fetchData"
            @rowSelected="atualizarLinhasSelecionas">
        </MatchingTableVenda>
        <MatchingTableCompra v-else :loading="loading" :dados="dadosCompra" @atualizarTabela="fetchData"
            @rowSelected="atualizarLinhasSelecionas">
        </MatchingTableCompra>
    </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import PageHeader from '@/components/page-header';
import meses from '@/utils/meses';
import {
    findByMesRef, tryMatching, validarSimplificadoMZerado, validarSimplificadoAjusteM, criaSimplificado,
    atualizaSimplificado, desfazerMatching
} from './ParcelaBoletaValidation.service';
import MatchingTableVenda from './MatchingTableVenda';
import MatchingTableCompra from './MatchingTableCompra';
import { hasPermission } from '@/features/auth/auth.acl';

Vue.use(VueMask);

export default {
    data() {
        return {
            mesRefInput: '',
            dados: null,
            dadosVenda: null,
            dadosCompra: null,
            meses: meses(),
            mesRef: '',
            filtroMesRef: new Date().getMonth() + 1,
            filtroAnoRef: new Date().getFullYear(),
            loading: false,
            linhas: [],
            breadcrumb: [
                {
                    text: 'Backoffice',
                    href: '#'
                },
                {
                    text: 'Validações',
                    active: true
                }
            ],
            operacoes: [
                { value: true, text: 'Vendas' },
                { value: false, text: 'Compras' }],
            venda: true,
            cores: [
                { value: 'todas', text: 'Todas' },
                { value: 'brancas', text: 'Brancas' },
                { value: 'vermelhas', text: 'Vermelhas' },
                { value: 'verdes', text: 'Verdes' },
                { value: 'azuis', text: 'Azuis' },],
            cor: 'todas',
            fontes: [
                { value: null, text: 'Todos' },
                { value: 'CO', text: 'CO' },
                { value: 'I0', text: 'I0' },
                { value: 'I5', text: 'I5' },
                { value: 'I1', text: 'I1' },
                { value: 'INE', text: 'INE' },
                { value: 'CQ5', text: 'CQ5' }],
            fonte: null,
            submercados: [
                { value: null, text: 'Todos' },
                { value: 'SE', text: 'SE' },
                { value: 'S', text: 'S' },
                { value: 'N', text: 'N' },
                { value: 'NE', text: 'NE' }],
            submercado: null,
            vr: ['sim', 'nao'],
            ar: ['sim', 'nao'],
            va: ['sim', 'nao'],
            fin: ['sim', 'nao'],
            mat: ['sim', 'nao'],
            net: ['sim', 'nao'],
            optionsCheck: [
                { text: 'Sim', value: 'sim' },
                { text: 'Não', value: 'nao' }],
            sigla: null,
            cliqccee: null
        }
    },
    components: {
        PageHeader, MatchingTableVenda, MatchingTableCompra
    },
    created() {
        this.atualizarDataRef();
    },
    methods: {
        getFiltro() {
            return {
                siglaPerfilCP: this.sigla,
                cliqccee: this.cliqccee,
                fonte: this.fonte,
                submercado: this.submercado,
                vrsim: this.vr.includes('sim'),
                vrnao: this.vr.includes('nao'),
                arsim: this.ar.includes('sim'),
                arnao: this.ar.includes('nao'),
                vasim: this.va.includes('sim'),
                vanao: this.va.includes('nao'),
                finsim: this.fin.includes('sim'),
                finnao: this.fin.includes('nao'),
                matchingsim: this.mat.includes('sim'),
                matchingnao: this.mat.includes('nao'),
                netsim: this.net.includes('sim'),
                netnao: this.net.includes('nao'),
            };
        },
        filtro() {
            this.dadosVenda = this.dados.boletas.filter(item => item.tipoBoleta === 'V');
            this.dadosCompra = this.dados.boletas.filter(item => item.tipoBoleta === 'C');
        },
        atualizarDataRef() {
            if (this.filtroMesRef && this.filtroAnoRef) {
                const mesIndexZeroBase = this.filtroMesRef - 1;
                this.mesRefInput = `${this.filtroAnoRef}-${String(
                    mesIndexZeroBase + 1
                ).padStart(2, '0')}-01`;
            }
        },
        fetchData() {
            this.loading = true;

            findByMesRef(this.mesRefInput, this.getFiltro())
                .then((response) => {
                    if (response.status && response.content) {
                        this.dados = response.content;
                        this.filtro();
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fazerMatching() {
            this.loading = true;

            tryMatching(this.mesRefInput)
                .then((response) => {
                    if (response.status == 200) {
                        this.fetchData();
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        criarContratos() {
            if (this.linhas.length <= 0) return;
            this.loading = true;

            criaSimplificado(this.linhas)
                .then((response) => {

                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                }).finally(() => {
                    this.loading = false;
                });
        },
        atualizarContratos() {
            if (this.linhas.length <= 0) return;
            this.loading = true;

            atualizaSimplificado(this.linhas)
                .then((response) => {

                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                }).finally(() => {
                    this.loading = false;
                });
        },
        validarContratosAjusteM() {
            if (this.linhas.length <= 0) return;
            this.loading = true;

            validarSimplificadoAjusteM(this.linhas)
                .then((response) => {

                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                }).finally(() => {
                    this.loading = false;
                });
        },
        validarContratosMZerado() {
            if (this.linhas.length <= 0) return;
            this.loading = true;

            validarSimplificadoMZerado(this.linhas)
                .then((response) => {

                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                }).finally(() => {
                    this.loading = false;
                });
        },
        atualizarLinhasSelecionas(items) {
            this.linhas = items.items;
            //console.log(this.linhas);
        },
        desfazerMatching() {
            if (this.linhas.length <= 0) return;
            this.loading = true;

            desfazerMatching(this.linhas)
                .then((response) => {

                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                }).finally(() => {
                    this.fetchData();
                    this.loading = false;
                });
        },
        formatNumber(number, decimals) {
            // Verifica se o número é zero
            if (number === 0.0) {
                return number;
            } else {
                // Caso contrário, formata o número com o número especificado de casas decimais
                return parseFloat(number).toFixed(decimals);
            }
        },
        hasPermission(requiredAcl) {
            return hasPermission(requiredAcl);
        },
        onCorChange() {
            switch (this.cor) {
                case "todas":
                    this.vr = ['sim', 'nao'];
                    this.ar = ['sim', 'nao'];
                    this.va = ['sim', 'nao'];
                    this.fin = ['sim', 'nao'];
                    this.mat = ['sim', 'nao'];
                    this.net = ['sim', 'nao'];
                    break;
                case "brancas":
                    this.mat = ['nao'];
                    this.net = ['nao'];
                    break;
                case "vermelhas":
                    this.mat = ['sim'];
                    this.net = ['nao'];
                    if (this.venda) {
                        this.ar = ['sim', 'nao'];
                        this.va = ['nao'];
                    } else {
                        this.ar = ['nao'];
                    }

                    break;
                case "verdes":
                    this.mat = ['sim'];
                    this.net = ['nao'];
                    if (this.venda) {
                        this.ar = ['sim', 'nao'];
                        this.va = ['sim'];
                    } else {
                        this.ar = ['sim'];
                    }

                    break;
                case "azuis":
                    this.net = ['sim'];
                    break;
            }
        }
    }
};
</script>

<style>
.form {
    width: 12vw;
}

.c {
    height: 25vh;
}

.buttom {
    width: 12vw;
}

.card-pequeno {
    font-size: 12px;
}

.card-pequeno .card-header {
    padding: 3px;
}

.card-pequeno .card-body {
    padding: 3px;
}

.margin {
    margin: 10px 0;
}
</style>