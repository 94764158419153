<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
  <div class="d-flex justify-content-center align-items-center" id="icon">
    <b-icon v-if=value icon="check-square" scale="2" variant="success"></b-icon>
    <b-icon v-else icon="x-square" scale="2" variant="danger"></b-icon>
  </div>
</template>

<style>
#icon{
  height: 20px;
}
</style>