<template>
    <b-form-select class="fonte" :options="operacoes" v-model="sub" size="sm" @change="change" />
</template>

<script>
export default {
    data() {
        return {
            operacoes: [
                { value: 'SE', text: 'SE' },
                { value: 'S', text: 'S' },
                { value: 'N', text: 'N' },
                { value: 'NE', text: 'NE' }]
        }
    },
    methods: {
        change() {
            this.$emit('submercadoSelecionado', this.sub, this.boleta);
        }
    },
    props: {
        sub: {
            type: String,
            default: 'SE'
        },
        boleta: {
            type: Object,
            default: {}
        }
    }
}
</script>