<template>
  <div v-if="dados && !loading">
    <b-table bordered outlined small :fields="fields" :items="dados"  class="custom-table"
      :tbody-tr-class="rowClass" @row-selected="onRowSelected" sticky-header :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc" style="max-height: 75vh;">
      <template #thead-top="data">
        <b-tr>
          <b-th colspan="1">
            <b-form-checkbox v-model="selectAll" @change="toggleSelectAll"></b-form-checkbox>
          </b-th>
          <b-th colspan="1"><b-p class="d-flex justify-content-center"></b-p></b-th>
          <b-th colspan="2" variant="warning"><b-p class="d-flex justify-content-center">Validações</b-p></b-th>
          <b-th colspan="13" variant="success"><b-p class="d-flex justify-content-center">Libra Sys</b-p></b-th>
          <b-th colspan="2" variant="danger"><b-p class="d-flex justify-content-center">Ajustes</b-p></b-th>
          <b-th colspan="6" variant="primary"><b-p class="d-flex justify-content-center">Automações</b-p></b-th>
          <b-th colspan="10" variant="secondary"><b-p class="d-flex justify-content-center">CCEE</b-p></b-th>
        </b-tr>
      </template>

      <template #cell(selected)="data">
        <b-form-checkbox v-model="data.item.selected" @change="onCheckboxChange(data.item)"></b-form-checkbox>
      </template>

      <template #cell(ajustes.fonte)="data">
        <FonteSelect :fonte="data.item.ajustes.fonte" :boleta="data.item" @fonteSelecionada="salvaFonte">
        </FonteSelect>
      </template>
      <template #cell(ajustes.submercado)="data">
        <SubmercadoSelect :sub="data.item.ajustes.submercado" :boleta="data.item"
          @submercadoSelecionado="salvaSubmercado"></SubmercadoSelect>
      </template>
      <template #cell(sys.inicio)="data">
        <p class="data">{{ data.value }}</p>
      </template>
      <template #cell(sys.fim)="data">
        <p class="data">{{ data.value }}</p>
      </template>
      <template #cell(ccee.suprimentoIni)="data">
        <p class="data">{{ data.value }}</p>
      </template>
      <template #cell(ccee.suprimentoFim)="data">
        <p class="data">{{ data.value }}</p>
      </template>
      <template #cell(ccee.vigIni)="data">
        <p class="data">{{ data.value }}</p>
      </template>
      <template #cell(ccee.vigfim)="data">
        <p class="data">{{ data.value }}</p>
      </template>
      <template #cell(netEnergetico)="data">
        <IconValid :value="data.item.netEnergetico"></IconValid>
      </template>
      <template #cell(statusFinanceiro)="data">
        <IconValid :value="data.item.statusFinanceiro"></IconValid>
      </template>
      <template #cell(statusPerfilAgente)="data">
        <IconValid :value="data.item.statusPerfilAgente"></IconValid>
      </template>
      <template #cell(statusSubmercado)="data">
        <IconValid :value="data.item.statusSubmercado"></IconValid>
      </template>
      <template #cell(validacaoRegistro)="data">
        <IconValid :value="data.item.validacaoRegistro"></IconValid>
      </template>
      <template #cell(ajusteRegistro)="data">
        <IconValid :value="data.item.ajusteRegistro"></IconValid>
      </template>
      <template #cell(validacaoAjuste)="data">
        <IconValid :value="data.item.validacaoAjuste"></IconValid>
      </template>

      <template #cell(sys.volume)="data">
        <p>{{ formatNumber(data.value, 6) }}</p>
      </template>

      <template #cell(sys.preco)="data">
        <p>{{ formatNumber(data.value, 3) }}</p>
      </template>

      <template #cell(valorTotalRegistro)="data">
        <p>{{ formatNumber(data.value, 3) }}</p>
      </template>

      <template #cell(sys.siglaPerfilParte)="data">
        <p class="perfil">{{ data.value }}</p>
      </template>

      <template #cell(sys.siglaPerfilContraParte)="data">
        <p class="perfil">{{ data.value }}</p>
      </template>

      <template #cell(ccee.vendedor)="data">
        <p class="perfil">{{ data.value }}</p>
      </template>

      <template #cell(ccee.comprador)="data">
        <p class="perfil">{{ data.value }}</p>
      </template>

      <template #cell(ccee.statusRegistro)="data">
        <p class="registro">{{ data.value }}</p>
      </template>

      <template #cell(mwMedioEsperado)="data">
        <p v-if="data.item.mwMedioEsperado != null">{{ formatNumber(data.value, 3) }}</p>
      </template>

      <template #cell(mwHoraEsperado)="data">
        <p v-if="data.item.mwHoraEsperado != null">{{ formatNumber(data.value, 3) }}</p>
      </template>

      <template #cell(mwMedioRealizado)="data">
        <p v-if="data.item.mwMedioRealizado != null">{{ formatNumber(data.value, 3) }}</p>
      </template>

      <template #cell(mwHoraRealizado)="data">
        <p v-if="data.item.mwHoraRealizado != null">{{ formatNumber(data.value, 3) }}</p>
      </template>

      <template #cell(balanco)="data">
        <p v-if="data.item.balanco != null">
          {{ formatNumber(data.value, 3) }}
        </p>
      </template>
    </b-table>
  </div>
</template>

<script>
import IconValid from '@/components/iconValid';
import FonteSelect from './FonteSelect';
import SubmercadoSelect from './SubmercadoSelect';
import { salvaEstrutura } from './ParcelaBoletaValidation.service';

export default {
  data() {
    return {
      fields: [
        { key: 'selected', label: '' },
        { key: 'cliqccee', label: 'CliqCCEE', headerTitle: 'Código CliqCCEE', sortable: true },
        {
          key: 'validacaoRegistro',
          label: 'VR',
          headerTitle: 'Validação Registro', sortable: true
        },
        { key: 'ajusteRegistro', label: 'AR', headerTitle: 'Ajuste Registro', sortable: true },
        { key: 'codBoleta', label: 'Boleta', headerTitle: 'Código Boleta', sortable: true },
        {
          key: 'sys.tipoContrato',
          label: 'PC',
          headerTitle: 'Período Contrato'
        },
        {
          key: 'sys.codigoPerfilParte',
          label: 'CodP',
          headerTitle: 'Código Perfil Parte'
        },
        {
          key: 'sys.siglaPerfilParte',
          label: 'PerfilP',
          headerTitle: 'Código Perfil Parte'
        },
        {
          key: 'sys.codigoPerfilContraParte',
          label: 'CodCP',
          headerTitle: 'Código Boleta Contra Parte'
        },
        {
          key: 'sys.siglaPerfilContraParte',
          label: 'PerfilCP',
          headerTitle: 'Código Perfil Contra Parte',
          sortable: true
        },
        {
          key: 'sys.nomeFantasia',
          label: 'Nome',
          headerTitle: 'Nome Fantasia'
        },
        {
          key: 'sys.tipoEnergia',
          label: 'Fonte',
          headerTitle: 'Fonte Energia'
        },
        { key: 'sys.submercado', label: 'Sub', headerTitle: 'Submercado' },
        { key: 'sys.volume', label: 'Vol', headerTitle: 'volume', sortable: true },
        { key: 'sys.inicio', label: 'Data Ini', headerTitle: 'Data Inicial' },
        { key: 'sys.fim', label: 'Data Fim', headerTitle: 'Data Final' },
        { key: 'sys.preco', label: 'Preço', headerTitle: 'Preço' },
        { key: 'ajustes.fonte', label: 'Fonte', headerTitle: 'Fonte Energia' },
        { key: 'ajustes.submercado', label: 'Sub', headerTitle: 'Submercado' },
        {
          key: 'valorTotalRegistro',
          label: 'Valor',
          headerTitle: 'Valor Total Registro'
        },
        {
          key: 'mwMedioEsperado',
          label: 'MWME',
          headerTitle: 'MW Médio Esperado'
        },
        {
          key: 'mwHoraEsperado',
          label: 'MWHE',
          headerTitle: 'MW Hora Esperado'
        },
        {
          key: 'mwMedioRealizado',
          label: 'MWMR',
          headerTitle: 'MW Médio Realizado'
        },
        {
          key: 'mwHoraRealizado',
          label: 'MWHR',
          headerTitle: 'MW Hora Realizado'
        },
        { key: 'balanco', label: 'Bal', headerTitle: 'Balanço' },
        { key: 'ccee.energia', label: 'Fonte', headerTitle: 'Fonte Energia' },
        { key: 'ccee.submercado', label: 'Sub', headerTitle: 'Submercado' },
        {
          key: 'ccee.suprimentoIni',
          label: 'Supri Ini',
          headerTitle: 'Suprimento Inicial'
        },
        {
          key: 'ccee.suprimentoFim',
          label: 'Supri Fim',
          headerTitle: 'suprimento Final'
        },
        { key: 'ccee.vendedor', label: 'Vendedor', headerTitle: 'Vendedor' },
        { key: 'ccee.comprador', label: 'Comprador', headerTitle: 'Comprador' },
        {
          key: 'ccee.statusRegistro',
          label: 'Registro',
          headerTitle: 'Status Registro'
        },
        {
          key: 'ccee.vigIni',
          label: 'Vig Ini',
          headerTitle: 'Vigência Inicial'
        },
        { key: 'ccee.vigFim', label: 'Vig Fim', headerTitle: 'Vigência Final' }
      ],
      selectAll: false,
      sortBy: 'codBoleta',
      sortDesc: false
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    dados: {
      type: Array,
      default: null
    }
  },
  components: {
    IconValid,
    FonteSelect,
    SubmercadoSelect
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return;

      // Verifica se a variável cliqccee está definida e não é nula
      if (item.cliqccee !== undefined && item.cliqccee !== null) {
        // Verifica se todos os booleanos são verdadeiros
        if (item.netEnergetico) {
          return 'table-primary';
        } else if (
          item.validacaoRegistro &&
          item.ajusteRegistro
        ) {
          return 'table-success';
        } else {
          return 'table-danger';
        }
      } else {
        // Se cliqccee não estiver definido ou for nulo, mantem a linha branca
        return '';
      }
    },
    toggleSelectAll() {
      if (this.selectAll) {
        this.dados.forEach((objeto) => {
          this.$set(objeto, 'selected', true);
        });
      } else {
        this.dados.forEach((objeto) => {
          this.$set(objeto, 'selected', false);
        });
      }

      this.onRowSelected(this.dados.filter((item) => item.selected));
    },
    atualizarTabela() {
      this.$emit('atualizarTabela');
    },
    salvaSubmercado(submercado, boleta) {
      boleta.ajustes.submercado = submercado;
      boleta.ajustes.updateSubmercado = true;

      salvaEstrutura(boleta)
        .then((response) => {
          if (response.status == 200) {
            this.atualizarTabela();
          } else {
            console.error(
              'Resposta do servidor não está no formato esperado:',
              response
            );
          }
        })
        .catch((error) => {
          console.error('Erro ao buscar dados:', error);
        });
    },
    salvaFonte(fonte, boleta) {
      boleta.ajustes.fonte = fonte;
      boleta.ajustes.updateFonte = true;

      salvaEstrutura(boleta)
        .then((response) => {
          if (response.status == 200) {
            this.atualizarTabela();
          } else {
            console.error(
              'Resposta do servidor não está no formato esperado:',
              response
            );
          }
        })
        .catch((error) => {
          console.error('Erro ao buscar dados:', error);
        });
    },
    onRowSelected(items) {
      //console.log('Selected rows:', items);
      this.$emit('rowSelected', { items: items });
    },
    onCheckboxChange(item) {
      this.onRowSelected(this.dados.filter((item) => item.selected));
    },
    formatNumber(number, decimals) {
      // Verifica se o número é zero
      if (number == 0) {
        return 0;
      } else {
        // Caso contrário, formata o número com o número especificado de casas decimais
        return number.toLocaleString('pt-BR', { minimumFractionDigits: decimals });
      }
    }
  }
};
</script>
