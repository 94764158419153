<template>
    <b-form-select class="fonte" :options="operacoes" v-model="fonte" size="sm" @change="change" />
</template>

<style>
.fonte {
    width: 60px;
    height: 20px;
    font-size: 9px;
}
</style>

<script>
export default {
    data() {
        return {
            operacoes: [
                { value: 'CO', text: 'CO' },
                { value: 'I0', text: 'I0' },
                { value: 'I5', text: 'I5' },
                { value: 'I1', text: 'I1' },
                { value: 'INE', text: 'INE' },
                { value: 'CQ5', text: 'CQ5' }]
        }
    },
    methods: {
        change() {
            this.$emit('fonteSelecionada', this.fonte, this.boleta);
        }
    },
    props: {
        fonte: {
            type: String,
            default: 'CO'
        },
        boleta: {
            type: Object,
            default: {}
        }
    }
}
</script>